import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="bg-primary shadow">
        <nav className="container mx-auto px-6 py-3 flex justify-between items-center">
          <div className="flex items-center">
            <img src={logo} alt="Company Logo" className="h-20" />
          </div>
          <ul className="flex items-center space-x-4">
            <li><a href="#home" className="text-white hover:text-accent">Home</a></li>
            <li><a href="#services" className="text-white hover:text-accent">Services</a></li>
            <li><a href="#about" className="text-white hover:text-accent">About Us</a></li>
            <li><a href="#contact" className="text-white hover:text-accent">Contact</a></li>
          </ul>
        </nav>
      </header>
      <main>
        <section className="bg-white py-8">
          <div className="container mx-auto px-6 text-center">
            <h2 className="text-3xl font-bold text-gray-800">Welcome to Tampa BTC</h2>
            <p className="mt-4 text-gray-600">
              Serving Tampa with innovative software solutions with an emphasis on bitcoin.
            </p>
            <p className="mt-4 text-gray-600">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla vel interdum justo, in faucibus quam. Vivamus sagittis posuere condimentum. Ut non imperdiet urna, molestie commodo odio. Etiam pharetra luctus cursus. Cras eleifend auctor nunc, ac vestibulum quam. Etiam eget dui congue, placerat arcu id, pellentesque metus. Nam mi tortor, molestie eleifend tristique et, cursus quis nunc.
            </p>
            <p className="mt-4 text-gray-600">
              Interdum et malesuada fames ac ante ipsum primis in faucibus. Mauris auctor nisi diam, et malesuada justo auctor ut. Aenean ut ipsum nec ex vestibulum semper. Aenean auctor, lorem sed malesuada pretium, turpis lacus volutpat tortor, eget facilisis dui augue sed ligula. Nullam sed magna ac mi pretium dapibus. Donec vestibulum tellus eget tempus egestas. Aliquam elementum, diam nec ultrices vestibulum, arcu tellus auctor arcu, et venenatis purus sem non lectus. Integer venenatis lacinia viverra.
            </p>
            <p className="mt-4 text-gray-600">
              Phasellus a sem laoreet, luctus purus a, fringilla neque. Nam luctus maximus tellus, nec tincidunt justo dapibus ut. Morbi non finibus nibh. Pellentesque ac tellus est. Aliquam ultrices, augue sed mollis tincidunt, tellus nunc bibendum nisl, at finibus enim ipsum imperdiet ante. Aliquam volutpat nunc rutrum nibh blandit, sed placerat nisi rutrum. Donec vulputate vitae turpis at blandit. Suspendisse vitae posuere lorem. In elit diam, semper vel enim nec, molestie blandit neque. Vestibulum vestibulum, ligula eu consequat pharetra, ligula dui pulvinar neque, in gravida magna nisi vel purus.
            </p>
            <p className="mt-4 text-gray-600">
              Phasellus volutpat tortor nulla, eu efficitur tellus finibus aliquam. Duis eu orci sed odio elementum auctor. Maecenas ornare sit amet risus vitae posuere. Phasellus finibus condimentum egestas. Fusce ultricies scelerisque fringilla. Nullam ultricies imperdiet sapien, commodo hendrerit orci interdum et. Fusce id pharetra felis. Proin a vestibulum mauris. Etiam ullamcorper consequat nunc id efficitur. Vivamus laoreet ultrices massa, quis pretium nulla fringilla a. Suspendisse suscipit, turpis at placerat ullamcorper, nibh ex venenatis erat, eget mattis dui felis at ligula. Praesent efficitur lacinia maximus. Donec accumsan lorem maximus, elementum nisi sed, pellentesque leo. Cras posuere congue turpis, quis varius lacus molestie a.
            </p>
            <p className="mt-4 text-gray-600">
              Donec pharetra aliquet ex, eu dictum lorem pulvinar eu. Morbi metus dui, gravida ac porta eu, rutrum a odio. Duis gravida elit id odio fermentum, at iaculis nulla gravida. Nam ultrices efficitur consequat. Cras a aliquam est. Nullam a neque aliquet, fermentum ipsum et, scelerisque urna. Nam egestas, quam a consectetur euismod, dui risus feugiat nulla, et fringilla nisl erat in elit. Aenean malesuada enim leo, quis hendrerit lacus hendrerit id. Vivamus a tortor sit amet est fermentum tempor non a velit. Maecenas diam mauris, tristique sit amet aliquam at, consectetur sed lacus. Aenean felis libero, tempor ac bibendum vitae, tempus sodales felis. Praesent vel sagittis orci.
            </p>
          </div>
        </section>
        <section className="bg-primary py-8 text-white">
          <div className="container mx-auto px-6 text-center">
            <h4 className="text-2xl font-bold text-gray-800">Get Started with Our Solutions</h4>
            <p className="mt-4 text-gray-600">Contact us today to discuss your software needs.</p>
            <a href="#contact" className="mt-6 inline-block bg-accent hover:bg-orange-700 text-white py-3 px-8 rounded transition duration-300">Contact Us</a>
          </div>
        </section>
        <section className="py-8">
          <div className="container mx-auto px-6">
            <h5 className="text-2xl font-bold text-gray-800 text-center">Contact Information</h5>
          </div>
        </section>
      </main>
      <footer className="bg-primary text-white border-t mt-8">
        <div className="container mx-auto px-6 py-4">
          <div className="flex flex-wrap justify-between items-center">
            <div className="w-full md:w-1/3 text-center md:text-left">
              <h6 className="font-bold text-gray-800">Tampa BTC</h6>
              <p className="text-sm text-gray-600 mt-2">
                123 Software St, Tampa, FL 12345<br />
                Email: info@tampabtc.com<br />
                Phone: (123) 456-7890
              </p>
            </div>
            <div className="w-full md:w-1/3 mt-4 md:mt-0 text-center">
              <h6 className="font-bold text-gray-800">Quick Links</h6>
              <ul className="mt-2 text-sm text-gray-600">
                <li><a href="#services" className="hover:text-orange-600">Our Services</a></li>
                <li><a href="#about" className="hover:text-orange-600">About Us</a></li>
                <li><a href="#contact" className="hover:text-orange-600">Contact</a></li>
              </ul>
            </div>
            <div className="w-full md:w-1/3 mt-4 md:mt-0 text-center md:text-right">
              <h6 className="font-bold text-gray-800">Follow Us</h6>
              <div className="mt-2">
              </div>
            </div>
          </div>
          <div className="text-center mt-4">
            <p className="text-sm text-gray-600">
              © {new Date().getFullYear()} Tampa BTC, All Rights Reserved.
            </p>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default App;
